<template>
  <tr>
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    <td class="justify-left">
      <strong>{{ props.item.name || '- - -' }}</strong>
    </td>
    <td class="justify-left" style="font-size: 20px;">
      <strong>{{ props.item.count || '- - -' }}</strong>
    </td>
    <td class="justify-left">
      <span v-if="props.item.active" class="process__status process__status--active">
        Ativo
      </span>
      <span v-else class="process__status process__status--inactive">
        Inativo
      </span>
    </td>
    <td class="justify-left" style="padding-top: 4px;">
      <v-btn slot="activator" outline small block color="orange" :disabled="!props.item.first_stage_id" @click="restartExecution(props.item.id)">
        <v-icon>play_circle_outline</v-icon>
        <span>Executar</span>
      </v-btn>
    </td>
    <td class="justify-left" style="padding-top: 4px;">
      <v-menu bottom right>
        <v-btn slot="activator" outline small block>
          <v-icon>settings</v-icon>
          <span>Opções</span>
        </v-btn>
        <v-list>
          <v-list-tile :disabled="!props.item.first_stage_id" @click="openProcessStages()">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon color="blue">format_list_bulleted</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Etapas do processo</v-list-tile-title>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile disabled>
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon color="red">message</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Execuções</v-list-tile-title>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile disabled>
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon color="yellow">schedule</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Em tempo real</v-list-tile-title>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile disabled>
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon color="green">leaderboard</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Estatísticas</v-list-tile-title>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile :disabled="!props.item.first_stage_id" @click="restartExecution(props.item.id)">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon color="orange">edit</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Executar processo</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </td>
  </tr>
</template>
<script>
  import processMixin from '@/modules/service-desk/pages/Process/processMixin'
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'Processes',
    methods: {
      openProcessStages: function () {
        this.$router.push({ name: 'process', params: { id: this.props.item.id } })
      }
    },
    props: {
      config: Object,
      props: Object
    },
    mixins: [
      processMixin
    ],
    components: {
      WiListDataTableActions
    }
  }
</script>
<style>
  .process__status {
    border-width: 1px;
    border-style: solid;
    padding: 2px 5px;
  }
  .process__status--active {
    color: #eceff1;
    background: rgb(4, 112, 0);
  }
  .process__status--inactive {
    color: grey;
    background: #eceff1;
  }
  td {
    padding: 0 5px !important;
  }
  th {
    padding: 0 5px !important;
  }
</style>